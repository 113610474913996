import { useState, useCallback } from "react";
import Pagination from "./Pagination";
import { LandSite } from "@prop-insight/database";
// import { useLoaderData } from "react-router-dom";
import SelectBox from "./SelectBox";
import {
  LandSiteAuthority,
  LandSitePurpose,
  LandStatus,
} from "contans/SelectItems";
import _ from "lodash";
import NewLandSiteTable from "./NewLandSiteTable";
import axiosInst from "utils/axiosInst";
import { LoadingSpinner } from "@prop-insight/components";
import useQuery from "hooks/useQuery";
import { getLandSite } from "utils/api/landSiteApi";

export interface NewLandPanelProps {
  openNewLandDetail: (landSite: LandSite) => void;
}

export default function NewLandPanel({ openNewLandDetail }: NewLandPanelProps) {
  // 입찰 상황
  const [currentLandStatus, setCurrentLandStatus] = useState<string>();
  const [currentLandSiteAuthority, setCurrentLandSiteAuthority] =
    useState<string>();
  const [currentLandSitePurpose, setCurrentLandSitePurpose] =
    useState<string>();

  const [currentPage, setCurrentPage] = useState(1);
  const [isNearApplicationEndDate, setIsNearApplicationEndDate] =
    useState<boolean>(false);

  const { data: landSiteData, isLoading } = useQuery<{
    count: number;
    landSites: LandSite[];
  }>(
    useCallback(
      () =>
        getLandSite({
          currentLandSiteAuthority,
          currentLandStatus,
          currentPage,
          isNearApplicationEndDate,
          currentLandSitePurpose,
        }),
      [
        currentLandSiteAuthority,
        currentLandSitePurpose,
        currentLandStatus,
        currentPage,
        isNearApplicationEndDate,
      ]
    )
  );

  // 상세정보 api호출
  const handleNewLandDetail = useCallback(
    async (id: number) => {
      try {
        const resp = await axiosInst.get(`/land-sites/${id}`);
        openNewLandDetail(resp.data);
      } catch (error) {
      } finally {
      }
    },
    [openNewLandDetail]
  );

  const handleToggleIsNearApplicationEndDate = () => {
    setCurrentPage(1);

    setIsNearApplicationEndDate(!isNearApplicationEndDate);
  };

  return (
    <div className="mx-[20px]">
      <div className="flex items-center mb-[23px]">
        <span className="text-text-darkgray text-title2 mr-[15px]">
          신규택지
        </span>
      </div>
      {/* 셀렉트 */}
      <div className="flex justify-between mb-[20px]">
        <div
          onClick={handleToggleIsNearApplicationEndDate}
          className={`${
            isNearApplicationEndDate
              ? "bg-primary-main-color"
              : "bg-secondary-main-color"
          } w-[82px] flex justify-center items-center rounded hover:bg-primary-main-color cursor-pointer`}
        >
          <span className="text-h2 text-white">마감임박순</span>
        </div>
        <SelectBox
          defaultValue="입찰상황별"
          keyValue={currentLandStatus ?? "입찰상황별"}
          className="min-w-[92px]"
          textDivClassName="-ml-[16px]"
          MenuClassName="w-[92px]"
          items={_.map(LandStatus, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);
            setCurrentLandStatus(e.key);
          }}
        />
        <SelectBox
          defaultValue="개발공사별"
          keyValue={currentLandSiteAuthority ?? "개발공사별"}
          className="min-w-[92px] pl-[10px]"
          textDivClassName="-ml-[13px]"
          textClassName="text-center"
          MenuClassName="w-[130px]"
          longTextItem
          items={_.map(LandSiteAuthority, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);

            setCurrentLandSiteAuthority(e.key);
          }}
        />
        <SelectBox
          defaultValue="공급용도별"
          keyValue={currentLandSitePurpose ?? "공급용도별"}
          className="min-w-[92px] pl-[10px]"
          textDivClassName="-ml-[13px]"
          textClassName="text-center"
          MenuClassName="w-[110px]"
          longTextItem
          items={_.map(LandSitePurpose, (val, key) => {
            return {
              key: key,
              value: val,
            };
          })}
          onChange={(e) => {
            setCurrentPage(1);

            setCurrentLandSitePurpose(e.key);
          }}
        />
      </div>

      {/* 표 */}
      {isLoading ? (
        <div className="flex items-center justify-center mt-[325px]">
          <LoadingSpinner panel />
        </div>
      ) : (
        landSiteData?.landSites && (
          <div>
            <NewLandSiteTable
              landSites={landSiteData.landSites}
              onClickDetailPanel={(landSite) =>
                handleNewLandDetail(landSite.id)
              }
            />
          </div>
        )
      )}

      {/* pagination */}
      <Pagination
        totalPages={
          landSiteData?.count ? Math.ceil(landSiteData.count / 10) : 1
        }
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
}
