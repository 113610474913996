import { Button } from "@prop-insight/components";
import { RouteObject, useNavigate } from "react-router-dom";
import withViewerTemplate from "hoc/withViewerTemplate";

function ServiceTerms() {
  const navigate = useNavigate();
  return (
    <div className="px-[57px] py-[46px]">
      <div className="flex flex-col mb-10">
        <span className="text-primary-main-color font-semibold text-2xl mb-8">
          서비스 이용약관
        </span>
        <div className="bg-background-sub-color px-[20px] py-[10px] h-[68.5vh] rounded-[10px] border border-solid border-secondary-main-color">
          <span className="text-[10px]">내용 입력.</span>
        </div>
      </div>
      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => navigate("/signup")}
        btnStyle="px-[21px]"
      >
        <span className="text-[13px]">확인</span>
      </Button>
    </div>
  );
}

const ServiceTermsWithSidebar = withViewerTemplate(ServiceTerms);

export default {
  path: "/service-terms",
  element: <ServiceTermsWithSidebar />,
} as RouteObject;
