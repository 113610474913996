import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrivateLandSite } from "@prop-insight/database";
import {
  PrivateLandSiteCategory,
  PrivateLandUseZone,
} from "contans/SelectItems";
import { useMap } from "providers/MapProvider";
import React from "react";
import axiosInst from "utils/axiosInst";

interface PrivateLandSiteTableProps {
  privateLandSites: PrivateLandSite[];
  onClickDetailPanel: (privateLandSite: PrivateLandSite) => void;
}

const MAIN_LOT_LAYER_NAME = "main-lot-layer";
const MAIN_LOT_SOURCE_NAME = "main-lot-source";

const PrivateLandSiteTable = ({
  privateLandSites,
  onClickDetailPanel,
}: PrivateLandSiteTableProps) => {
  const { setMapState, map } = useMap();

  return (
    <div>
      {privateLandSites.map((privateLandSite) => {
        // 담당부서별 색상
        const getColor = (privateLandSite: PrivateLandSite) => {
          const category =
            PrivateLandSiteCategory[privateLandSite.landCategory];

          if (category === "임야") {
            return "bg-[#427EB5]";
          } else if (category === "대지") {
            return "bg-[#FF9737]";
          } else if (category === "공장") {
            return "bg-[#FF6056]";
          } else if (category === "종교") {
            return "bg-[#1A6667]";
          }
        };

        return (
          <div
            onClick={() => {
              onClickDetailPanel(privateLandSite);
            }}
            className="w-full h-20 cursor-pointer flex items-center justify-between border-b-[1px]"
            key={privateLandSite.id}
          >
            <div
              className={`w-[38.9px] h-[31px] rounded flex items-center justify-center text-white text-p4 font-semibold ${getColor(
                privateLandSite
              )}`}
            >
              {PrivateLandSiteCategory[privateLandSite.landCategory]}
            </div>
            <div className="w-[46px] text-p3 text-center">
              {PrivateLandUseZone[privateLandSite.landUseZone]}
            </div>
            <div className="w-[181px]">
              <p className="text-p3 font-bold">{privateLandSite.title}</p>
              <p className="text-p3">{privateLandSite.address}</p>
            </div>
            <div className="w-[72px] text-p3 text-center">
              {privateLandSite.address.split(" ")[0]}
            </div>
            <div className="h-[31px] flex items-center">
              <FontAwesomeIcon
                icon={faMapLocationDot}
                size="lg"
                onClick={(event) => {
                  event.stopPropagation();

                  // Remove previous layers
                  map.current.getStyle().layers.forEach((key: any) => {
                    if (key.id.includes(MAIN_LOT_LAYER_NAME)) {
                      map.current.removeLayer(key.id);
                    }
                  });

                  // Remove previous sources
                  Object.keys(map.current.getStyle().sources).forEach((key) => {
                    if (key.includes(MAIN_LOT_SOURCE_NAME)) {
                      map.current.removeSource(key);
                    }
                  });

                  const coordinates = privateLandSite.jijukIds.map(
                    async (jijukId) => {
                      const res = await axiosInst.get(`/jijuk/${jijukId}`);

                      const jibunName = res.data.jijuk[0].jibunName;
                      const newCoordinates = JSON.parse(
                        res.data.jijuk[0].geometry
                      ).coordinates;

                      return {
                        id: jibunName,
                        coordinates: newCoordinates,
                      };
                    }
                  );

                  Promise.all(coordinates).then((coordinates) => {
                    coordinates.forEach(({ id, coordinates }: any) => {
                      const SOURCE_NAME = MAIN_LOT_SOURCE_NAME + "-" + id;
                      const LAYER_NAME = MAIN_LOT_LAYER_NAME + "-" + id;

                      if (map.current.getSource(SOURCE_NAME)) {
                        map.current.getSource(SOURCE_NAME).setData({
                          type: "Feature",
                          geometry: {
                            type: "Polygon",
                            // These coordinates outline Maine.
                            coordinates,
                          },
                        });
                      } else {
                        // Add a data source containing GeoJSON data.
                        map.current.addSource(SOURCE_NAME, {
                          type: "geojson",
                          data: {
                            type: "Feature",
                            geometry: {
                              type: "Polygon",
                              // These coordinates outline Maine.
                              coordinates,
                            },
                          },
                        });

                        // Add a new layer to visualize the polygon.
                        map.current.addLayer({
                          id: LAYER_NAME,
                          type: "fill",
                          source: SOURCE_NAME, // reference the data source
                          layout: {},
                          paint: {
                            "fill-color": "#0080ff", // blue color fill
                            "fill-opacity": 0.5,
                          },
                        });
                      }
                    });
                  });

                  setMapState({
                    lat: privateLandSite.lat,
                    lng: privateLandSite.lng,
                  });
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrivateLandSiteTable;
