import { Post } from "@prop-insight/database";
import moment from "moment";
import "moment/locale/ko";

interface PostDetailPanelProps {
  post: Post;
}

const PostDetailPanel = ({ post }: PostDetailPanelProps) => {
  return (
    <>
      <div className="mx-5 my-[70px] mb-[10px] text-lg font-bold leading-[1.125rem]">
        상세 정보
      </div>
      <div className="w-[390px] h-[540px] flex flex-col gap-[12px] mx-auto rounded-[10px] bg-[#F3F2F2] px-[12px] py-[18px]">
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            제목
          </div>
          <div className=" text-xs font-normal">{post.title}</div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            작성자
          </div>
          <div className=" text-xs font-normal">관리자</div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            작성날짜
          </div>
          <div className=" text-xs font-normal">
            {moment(post.createdAt).format("YYYY.MM.DD") +
              moment(post.createdAt).format("dddd").slice(0, 1) +
              " " +
              moment(post.createdAt).format("hh:mm")}
          </div>
        </div>
        <div className="flex items-center gap-[5px]">
          <div className="w-[68px] h-[18px] text-center text-h3 rounded border-[1px] border-[#333333]">
            내용
          </div>
          <div
            className=" text-xs font-normal"
            dangerouslySetInnerHTML={{ __html: post.body }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default PostDetailPanel;
