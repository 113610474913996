import React, { useCallback, useMemo, useState, ChangeEvent } from "react";
import { faStar, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@prop-insight/components";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { BookMarkItem } from "types/types";

interface BookMarkTableProps {
  bookMarkList: BookMarkItem[];
  userToken?: boolean;
  onClickDetailPanel: (bookMark: BookMarkItem) => void;
  onSaveMemo: (id: number, memo: string) => void;
  onDeleteFavoriteModal: (id: number) => void;
  calcArea: boolean;
}

const BookMarkTable = ({
  bookMarkList,
  userToken,
  onSaveMemo,
  onClickDetailPanel,
  onDeleteFavoriteModal,
  calcArea,
}: BookMarkTableProps) => {
  const navigate = useNavigate();

  const [selectedMemoIndex, setSelectedMemoIndex] = useState<number | null>(
    null
  );
  const [bookmarkMemo, setBookmarkMemo] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBookmarkMemo(event.target.value);
  };

  const handleShowMemo = useCallback(
    (bookmark: BookMarkItem) => {
      if (selectedMemoIndex === bookmark.id) {
        setSelectedMemoIndex(null);
        setBookmarkMemo("");
      } else {
        setSelectedMemoIndex(bookmark.id);
        setBookmarkMemo(bookmark.memo);
      }
    },
    [selectedMemoIndex]
  );

  const bookMarkTableMarkup = useMemo(() => {
    if (!userToken) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="flex flex-col items-center mt-[289px]">
              <span className="text-title text-primary-main-color font-semibold text-center h-[29px] -ml-1">
                로그인이 되어있지
              </span>
              <span className="text-title text-primary-main-color font-semibold text-center mb-[11px] h-[29px]">
                않습니다.
              </span>
              <span className="text-p2 text-primary-main-color text-center mb-[45px]">
                즐겨찾기 기능은 사용자의 로그인이 필요합니다.
              </span>
              <Button
                type="button"
                color="primary"
                variant="contained"
                btnStyle="max-w-[159px] px-[23px]"
                boxShadow
                onClick={() => navigate("/login", { replace: true })}
              >
                <span className="text-h2">로그인 페이지로 이동</span>
              </Button>
            </div>
          </td>
        </tr>
      );
    } else if (bookMarkList.length === 0) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="flex flex-col mt-[334px]">
              <span className="text-title text-primary-main-color font-semibold text-center mb-1">
                즐겨찾기 항목 없음
              </span>
              <span className="text-p2 text-primary-main-color text-center">
                즐겨찾기 등록한 항목이 존재하지 않습니다.
              </span>
            </div>
          </td>
        </tr>
      );
    } else {
      return (
        <>
          {_.map(bookMarkList, (bookmark) => (
            <>
              <tr
                className="border-b border-background-sub-color cursor-pointer"
                key={`land-site-${bookmark.id}`}
                onClick={() => {
                  handleShowMemo(bookmark);
                  onClickDetailPanel(bookmark);
                }}
              >
                <td className="min-w-[241px] px-[5px] pt-[15px] pb-[13px]">
                  <div className="flex items-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faStar}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDeleteFavoriteModal(bookmark.id);
                        }}
                        className="text-primary-main-color mr-[1px] -mt-[2px] cursor-pointer"
                      />
                    </div>

                    <div className="flex flex-col">
                      <span className="font-normal text-text-darkgray text-p3 mt-1 line-clamp-1">
                        {bookmark.jibunName}
                      </span>

                      <span className="text-[10px] font-normal text-secondary-main-color ml-[1px]">
                        {moment(bookmark.updatedAt).format("YY.MM.DD")} 저장됨
                      </span>
                    </div>
                  </div>
                </td>
                <td className="font-normal text-text-darkgray pt-[15px] pb-[13px] px-[12px] text-p3 whitespace-nowrap">
                  {calcArea
                    ? `${(bookmark.area * 0.3025).toFixed(2)}평`
                    : `${bookmark.area.toFixed(2)}m²`}
                </td>
                <td className="text-[12px] font-normal text-center text-text-darkgray pt-[15px] pb-[13px] px-[12px] text-p3">
                  {bookmark.zoneClass}
                </td>
              </tr>

              {/* 메모 출력 */}
              {selectedMemoIndex === bookmark.id && (
                <tr className="border-b border-background-sub-color">
                  <td colSpan={3} className="pt-[7px] pb-[12px]">
                    <div className="w-full bg-background-main-color px-[14px] py-[15px] border rounded border-secondary-main-color">
                      <textarea
                        className="w-full min-h-[86px] resize-none bg-transparent focus:outline-none text-p3 text-text-darkgray placeholder-secondary-main-color placeholder-text-p3 "
                        placeholder="메모를 입력해주세요."
                        value={bookmarkMemo}
                        onChange={handleInputChange}
                      />
                      <div className="flex justify-end mt-[5px] -mr-[2px]">
                        <Button
                          color="primary"
                          variant="outlined"
                          btnStyle="mr-[10px] px-[8px]"
                          boxShadow
                          bookMark
                          onClick={() => {
                            onSaveMemo(bookmark.id, "");
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} className="w-2" />
                          <span
                            className="text-p5"
                            style={{
                              transform: "scale(0.9)",
                              marginLeft: 1,
                              marginTop: 1,
                              fontWeight: 400,
                            }}
                          >
                            메모삭제
                          </span>
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          btnStyle="px-[6px] px-[8px]"
                          boxShadow
                          bookMark
                          onClick={() => {
                            onSaveMemo(bookmark.id, bookmarkMemo);
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          <span
                            className="text-p5"
                            style={{
                              transform: "scale(0.9)",
                              marginLeft: 1,
                              fontWeight: 400,
                            }}
                          >
                            메모저장
                          </span>
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </>
      );
    }
  }, [
    bookMarkList,
    bookmarkMemo,
    calcArea,
    handleShowMemo,
    navigate,
    onClickDetailPanel,
    onDeleteFavoriteModal,
    onSaveMemo,
    selectedMemoIndex,
    userToken,
  ]);

  return (
    <>
      <table className="w-full">
        <thead>
          <tr className="border-b border-background-sub-color">
            <th className="text-p3 font-normal text-text-darkgray px-[12px] py-[12px] min-w-[241px]">
              위치
            </th>
            <th className="text-p3 font-normal text-text-darkgray py-[12px] px-[12px]">
              토지면적
            </th>
            <th className="text-p3 font-normal text-text-darkgray py-[12px] px-[12px] whitespace-nowrap">
              용도지역
            </th>
          </tr>
        </thead>

        <tbody>{bookMarkTableMarkup}</tbody>
      </table>
    </>
  );
};

export default BookMarkTable;
